// Auth
export const API_AUTH_LOGIN = "/auth/login";
export const API_AUTH_REFRESH = "/auth/jwt-refresh";
export const API_PASSWORD_EMAIL = "/auth/forgot-password";
export const API_PASSWORD_RESET = "/auth/reset-password";

// Bank accounts
export const API_BANK_ACCOUNT_TYPES = "/bank-account-types";
export const API_BANK_ACCOUNT_ROLES = "/bank-account-roles";
export const API_BANK_ACCOUNTS = "/bank-accounts";
export const API_BANK_ACCOUNT_BALANCES = "/bank-accounts/balances";
export const API_BANK_ACCOUNT_UPDATE_BALANCE = "/bank-accounts/update-balance";

// Weekflow
export const API_WEEKFLOW_FINISH = "/weekflow/finish";

// Transactions
export const API_TRANSACTIONS = "/transactions";
export const API_TRANSACTION_SEARCH = "/transactions/search";
export const API_TRANSACTION_TEMPLATES = "/transaction-templates";
export const API_TRANSACTION_TEMPLATE_DELETE = "/transaction-templates/delete";
export const API_TRANSACTION_STATUSES = "/transaction-statuses";
export const API_TRANSACTION_TYPES = "/transaction-types";

// Customer
export const API_CUSTOMERS_DETAIL = "/customers/details";
export const API_CUSTOMERS_UPDATE_FINANCIAL_DETAILS = "/customers/update-financial-details";
export const API_CUSTOMERS_UPDATE_DETAILS = "/customers/update-details";
export const API_CUSTOMERS_UPDATE_PASSWORD = "/customers/update-password";
export const API_CUSTOMERS_UPDATE_NUMBER_OF_ACCOUNTS = "/customers/update-number-of-bankaccounts";
export const API_CUSTOMERS_FLOW_UPDATED = "/customers/set-flow-updated";
export const API_CUSTOMERS_ONBOARDING_COMPLETED = "/customers/set-onboarding-completed";

// User
export const API_USERS_APPLICATION_SETTINGS = "/users/application-settings";

// Calculations
export const API_CALCULATIONS_PROFIT_NEEDED = "/calculations/profit-needed";

// Dashboard
export const API_DASHBOARD_COMPLETE = "/dashboards/complete";
export const API_DASHBOARD_REVENUE_TARGET = "/dashboards/revenue-target";
export const API_DASHBOARD_COST_TARGET = "/dashboards/cost-target";
export const API_DASHBOARD_EXTRA_DATA = "/dashboards/extra-data";
