/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { createI18n } from "vue-i18n";
import { loadFonts } from "./webfontloader";
import vuetify from "./vuetify";
import router from "../router";
import VIdle from "v-idle-3";
import * as Sentry from "@sentry/vue";
import { stagePlayPlugin } from "vue-stage-play";

// Types
import type { App } from "vue";
import { useTranslations } from "@/composables/useTranslations";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";

import("dayjs/locale/nl.js");

const { defaultLocale, languages } = useTranslations();
const messages = Object.assign(languages);

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: "nl",
  messages,
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

export function registerPlugins(app: App) {
  if (import.meta.env.PROD) {
    Sentry.init({
      app,
      dsn: "https://37a02198b739f035fa6fc208df701058@o4506489416450048.ingest.sentry.io/4506489420120064",
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  loadFonts();

  app
    .use(vuetify)
    .use(i18n)
    .use(router)
    .use(VIdle, {})
    .use(
      stagePlayPlugin({
        allowInteract: false,
        allowLeave: false,
        voiceOverWidth: 600,
        voiceOverAlign: "start",
        voiceOverPrevButtonText: "Terug",
        voiceOverNextButtonText: "Volgende",
        voiceOverDoneButtonText: "Tip afsluiten",
      }),
    )
    .use(pinia);

  // const currentLocale = computed(() => i18n.global.locale);
  //
  // watch(currentLocale, (value) => {
  //   updateLocale(currentLocale.value);
  // });
}
