import { defineStore } from "pinia";
import { ref } from "vue";
import { AUTO_LOGOUT_SECONDS } from "@/constants/env";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const accessToken = ref<string>();
    const isAuthenticated = ref<boolean>(false);
    const isRefreshing = ref<boolean>(false);

    const setAccessToken = (token?: string) => {
      accessToken.value = token;
      if (token) {
        localStorage.setItem("accessToken", token);
        localStorage.setItem("_expiredTime", String(Date.now() + AUTO_LOGOUT_SECONDS * 1000));
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("_expiredTime");
      }
    };

    const getAccessToken = (): string | null => {
      if (!accessToken.value) {
        accessToken.value = localStorage.getItem("accessToken") ?? undefined;
      }

      return accessToken.value ?? null;
    };

    const clearTokens = () => {
      // await setRefreshToken(undefined);
      setAccessToken(undefined);
      isAuthenticated.value = false;
    };

    return {
      isAuthenticated,
      isRefreshing,
      setAccessToken,
      getAccessToken,
      clearTokens,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
);
