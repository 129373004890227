import en from "@/locales/en";
import nl from "@/locales/nl";

export function useTranslations() {
  const defaultLocale = "nl";

  const languages = {
    nl,
    en,
  };

  return {
    defaultLocale,
    languages,
  };
}
