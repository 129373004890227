import type { RouteRecordRaw } from "vue-router";
import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_ACCOUNT,
  ROUTE_ACCOUNT_BANKACCOUNTS,
  ROUTE_ACCOUNT_CHANGE_BANKACCOUNTS,
  ROUTE_ACCOUNT_DATA,
  ROUTE_UPDATE_PASSWORD,
} from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_ACCOUNT_ACCOUNT,
    name: "account",
    component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountView.vue"),
  },
  {
    path: ROUTE_ACCOUNT_DATA,
    name: "account-data",
    component: () => import(/* webpackChunkName: "account" */ "@/views/Account/ChangeFinancialDataView.vue"),
  },
  {
    path: ROUTE_ACCOUNT_BANKACCOUNTS,
    name: "bankaccounts",
    component: () => import(/* webpackChunkName: "bankAccounts" */ "@/views/Account/BankAccountsView.vue"),
  },
  {
    path: ROUTE_ACCOUNT_CHANGE_BANKACCOUNTS,
    name: "change-bankaccounts",
    component: () => import(/* webpackChunkName: "bankAccounts" */ "@/views/Account/ChangeBankAccountsView.vue"),
  },
  {
    path: ROUTE_ACCOUNT,
    name: "overview",
    component: () => import(/* webpackChunkName: "overView" */ "@/views/Account/OverviewView.vue"),
  },
  {
    path: ROUTE_UPDATE_PASSWORD,
    name: "update-password",
    component: () => import(/* webpackChunkName: "updatePassword" */ "@/views/Account/UpdatePasswordView.vue"),
  },
];

export default routes;
