<template>
  <v-dialog v-model="showModal" max-width="1080" :width="width">
    <v-card class="modal" :class="modalClass">
      <div v-if="showToolbar">
        <v-toolbar color="white">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCloseModal">
              <v-icon icon="cross" color="secondary" size="small"></v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider class="mb-1" />
      </div>
      <component :is="componentName" :componentProps="componentProps" />
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, shallowRef } from "vue";
import mitt from "@/utils/mitt";

mitt.on("openModal", (props: any) => {
  showModal.value = true;
  componentName.value = props.component;
  title.value = props.title;
  showToolbar.value = props.showToolbar ?? true;
  modalClass.value = props.modalClass;
  componentProps.value = props.componentProps;
  width.value = props.width ?? 750;
});
mitt.on("closeModal", () => {
  handleCloseModal();
});

const showModal = ref<boolean>(false);
const componentName = shallowRef<any>();
const title = ref<string>();
const showToolbar = ref<boolean>(true);
const componentProps = ref<any>();
const width = ref<number>(0);
const modalClass = ref<string>();

const handleCloseModal = () => {
  showModal.value = false;
};
</script>

<style scoped></style>
