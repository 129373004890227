import type { ThemeDefinition } from "vuetify";

export const debbieTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#8794F2",
    surface: "#fff",
    secondary: "#000138",
  },
  variables: {},
};
