<template>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="primary" variant="text" @click="snackbar = false">
        {{ $t("common.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { ref } from "vue";
import mitt from "@/utils/mitt";

withDefaults(defineProps<{ timeout: number }>(), {
  timeout: 4000,
});

const snackbar = ref(false);
const text = ref<String>();

mitt.on("showNotification", (notificationText: any) => {
  snackbar.value = true;
  text.value = notificationText;
});
</script>
