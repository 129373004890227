import { h } from "vue";
import type { IconProps, IconSet } from "vuetify";

const aliases = {
  checkboxOn: "mdi:mdi-checkbox-marked",
  checkboxOff: "mdi:mdi-checkbox-blank-outline",
  checkboxIndeterminate: "mdi:mdi-minus-box",
  radioOn: "mdi:mdi-radiobox-marked",
  radioOff: "mdi:mdi-radiobox-blank",
  arrow: "icon-arrow",
  arrowUp: "icon-chevron-up",
  arrowDown: "icon-chevron-down",
  addressbook: "icon-addressbook",
  alarm: "icon-alarm",
  calendar: "icon-calendar",
  chevronBackward: "icon-chevron-backward",
  chevronDown: "icon-chevron-down",
  chevronForward: "icon-chevron-forward",
  chevronUp: "icon-chevron-up",
  comment: "icon-comment",
  contact: "icon-contact",
  copy: "icon-copy",
  file: "icon-file",
  filter: "icon-filter",
  globe: "icon-globe",
  graph: "icon-graph",
  heart: "icon-heart",
  help: "icon-help",
  hidden: "icon-hidden",
  incoming: "icon-incoming",
  light: "icon-light",
  lock: "icon-lock",
  marker: "icon-marker",
  minus: "icon-minus",
  money: "icon-money",
  outgoing: "icon-outgoing",
  phone: "icon-phone",
  piechart: "icon-piechart",
  plus: "icon-plus",
  search: "icon-search",
  star: "icon-star",
  time: "icon-time",
  trash: "icon-trash",
  user: "icon-user",
  visible: "icon-visible",
  wallet: "icon-wallet",
  cancel: "...",
  prev: "chevron-backward",
  ratingEmpty: "...",
  delimiter: "...",
  warning: "...",
  info: "...",
  last: "skip",
  edit: "...",
  sort: "2",
  loading: "3",
  dropdown: "chevron-down",
  expand: "5",
  success: "6",
  next: "chevron-forward",
  subgroup: "...",
  error: "...",
  delete: "trash",
  ratingFull: "...",
  close: "cross",
  clear: "...",
  ratingHalf: "...",
  menu: "...",
  complete: "." + "..",
  collapse: "...",
  first: "first",
  unfold: "...",
};

const debbie: IconSet = {
  component: (props: IconProps) => h("span", { class: `icon-${props.icon}` }),
};

export { aliases, debbie };
