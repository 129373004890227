import applyCaseMiddleware from "axios-case-converter";
import { BASE_URL } from "@/constants/env";
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import axiosInstance, { AxiosHeaders, AxiosResponse } from "axios";
import { API_AUTH_LOGIN, API_AUTH_REFRESH } from "@/constants/api";
import { ROUTE_LOGIN } from "@/constants/routes";
import mitt from "@/utils/mitt";

const instance = axiosInstance.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const refreshTokens = (failedRequest: any): Promise<any> =>
  instance
    .post(API_AUTH_REFRESH, null, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig)
    .then((tokenRefreshResponse) => {
      localStorage.setItem("accessToken", tokenRefreshResponse.data.data.accessToken);

      failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.data.accessToken;
      return Promise.resolve();
    })
    .catch(async () => {
      localStorage.removeItem("accessToken");
      window.location.href = ROUTE_LOGIN;
    });

createAuthRefreshInterceptor(instance, refreshTokens);

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const originalConfig = error.config;

    if (originalConfig.url !== API_AUTH_LOGIN && error.response) {
      if (error.response.status === 403) {
        localStorage.removeItem("accessToken");
        window.location.href = ROUTE_LOGIN;
      } else {
        console.debug(error);
      }
    }

    if (originalConfig.url !== API_AUTH_LOGIN) {
      mitt.emit("showError", error);
    }

    return Promise.reject(error);
  },
);

// Obtain the fresh token each time the function is called
function getAccessToken() {
  return localStorage.getItem("accessToken");
}

// Use interceptor to inject the token to requests
instance.interceptors.request.use((request) => {
  if (request.headers) {
    const accessToken = getAccessToken();
    if (accessToken) {
      (request.headers as AxiosHeaders).set("Authorization", `Bearer ${accessToken}`);
    }
  }
  return request;
});

const api = applyCaseMiddleware(instance);

export default api;
