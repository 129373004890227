/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import { aliases, debbie } from "@/iconsets/debbie";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { nl } from "vuetify/locale";
import { debbieTheme } from "@/themes/debbie";
import { mdi } from "vuetify/iconsets/mdi";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    components,
  },
  directives,
  locale: {
    locale: "nl",
    messages: { nl },
  },
  theme: {
    defaultTheme: "debbieTheme",
    themes: {
      debbieTheme,
    },
  },
  defaults: {
    VExpansionPanel: {
      elevation: 0,
    },
    VTextField: {
      hideDetails: "auto",
    },
    VInput: {
      hideDetails: "auto",
    },
    VField: {
      hideDetails: "auto",
    },
    VCard: {
      variant: "outlined",
    },
    VBtn: {
      variant: "flat",
    },
  },
  icons: {
    defaultSet: "debbie",
    aliases,
    sets: {
      debbie,
      mdi,
    },
  },
});
