<template>
  <v-app>
    <modal-base-component />
    <RouterView />
    <DebbieNotification ref="notificationComponent" :timeout="3000" />
  </v-app>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import ModalBaseComponent from "@/components/Modals/ModalBaseComponent.vue";
import DebbieNotification from "@/components/DebbieNotification.vue";
import mitt from "@/utils/mitt";
import axios from "axios";

mitt.on("showError", (error: any) => {
  let errorMessage = "error"; // t("common.genericError");
  if (error.message) {
    errorMessage = error.message;
  }
  if (axios.isAxiosError(error) && error.response?.data.message) {
    errorMessage = error.response.data.message;
  }
  mitt.emit("showNotification", errorMessage);
});
</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
  }

  header {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
